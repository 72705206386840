import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Heading1 } from "./elements/Section";
import { themeColors } from "../styles/colors";
import { screen } from "../styles/mixins";
const Text = styled.span`
	color: ${(props) => themeColors[props.colorTheme].accent1};
`;

const FlipContainer = styled.div`
	position: relative;
	perspective: 20em;
	width: 100%;

	@media ${screen("md")} {
		width: auto;
	}
`;

const Flipper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.5s;
	transform-origin: 0 50%;
	width: 100%;

	@media ${screen("md")} {
		width: auto;
	}
`;

const Heading = styled.span`
	font-size: 55px;
	line-height: 1.05;
	font-weight: 600;
	letter-spacing: -0.03em;
	-webkit-font-smoothing: initial;
	-moz-osx-font-smoothing: initial;

	@media ${screen("designM")} {
		font-weight: 500;
		font-size: 65px;
	}

	@media ${screen("md")} {
		font-size: 70px;
	}
`;


const TextA = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	transform-style: preserve-3d;
	backface-visibility: hidden;
	width: 100%;
`;

const TextB = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transform: rotate3d(1, 0, 0, 180deg);
	transform-style: preserve-3d;
	backface-visibility: hidden;
`;

export default function HeroHomeTextFlip({ colorTheme }) {
	const titles = [
		"possibilities",
		"freedom",
		"opportunities",
		"simplicity",
		"potential",
	];

	const [enabled, setEnabled] = useState(true);
	const [textA, setTextA] = useState(titles[0]);
	const [textB, setTextB] = useState(titles[1]);
	const [rotation, setRotation] = useState(0);

	const currentText = useRef(0);
	const currentRotation = useRef(0);
	const fn = useRef();

	const enableAnimation = () => {
		setEnabled(true);
	};

	const disableAnimation = () => {
		setEnabled(false);
	};

	useEffect(() => {
		window.addEventListener("focus", enableAnimation);
		window.addEventListener("blur", disableAnimation);

		return () => {
			window.removeEventListener("focus", enableAnimation);
			window.removeEventListener("blur", disableAnimation);
		};
	}, []);

	useEffect(() => {
		if (enabled) {
			fn.current = setInterval(() => {
				currentRotation.current += 180;
				currentText.current += 1;

				if (currentText.current % 2 == 0)
					setTextA(titles[(currentText.current + 1) % titles.length]);
				else setTextB(titles[(currentText.current + 1) % titles.length]);

				setRotation(currentRotation.current);
			}, 2500);
		} else {
			clearInterval(fn.current);
		}
	}, [enabled]);

	return (
		<FlipContainer>
			<Heading1 as="span">&nbsp;</Heading1>
			<Flipper style={{ transform: `rotate3d(1,0,0,${rotation}deg)` }}>
				<TextA>
					<Heading>
						<Text medium colorTheme={colorTheme}>
							{textA}
						</Text>
					</Heading>
				</TextA>

				<TextB>
					<Heading>
						<Text medium colorTheme={colorTheme}>
							{textB}
						</Text>
					</Heading>
				</TextB>
			</Flipper>
		</FlipContainer>
	);
}
